import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-477da462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "phone-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item_rest = _resolveComponent("a-form-item-rest")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form_item_rest, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.prefix,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.prefix) = $event)),
          class: "phone-number__prefix",
          "show-search": "",
          size: _ctx.size,
          "default-active-first-option": false,
          onSelect: _ctx.update
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phonePrefixOptions, (prefixObj, index) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                value: index,
                key: prefixObj.isoCode
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(prefixObj.dialCode), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value", "size", "onSelect"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_a_input, {
      size: _ctx.size,
      class: "phone-number__number",
      placeholder: _ctx.placeholder,
      onChange: _ctx.update,
      value: _ctx.number,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.number) = $event)),
      maxlength: _ctx.maxLength
    }, null, 8 /* PROPS */, ["size", "placeholder", "onChange", "value", "maxlength"])
  ]))
}